import Axios from 'axios'

export default {
  axios() {
    let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
    return Axios.create({
      headers: {
        'X-CSRF-TOKEN': csrfToken,
        'Accept': 'application/json',
        'access-token': this.accessToken(),
        'client': this.client(),
        'uid': this.uid()
      }
    })
  },
  login(email, accessToken, client, uid) {
    localStorage.setItem('email', email)
    localStorage.setItem('access_token', accessToken)
    localStorage.setItem('client', client)
    localStorage.setItem('uid', uid)
  },
  logout() {
    localStorage.setItem('email', '')
    localStorage.setItem('access_token', '')
    localStorage.setItem('client', '')
    localStorage.setItem('uid', '')
  },
  email() {
    return localStorage.getItem('email')
  },
  saveEmail(email) {
    localStorage.setItem('email', email)
  },
  accessToken() {
    return localStorage.getItem('access_token')
  },
  saveAccessToken(accessToken) {
    localStorage.setItem('access_token', accessToken)
  },
  client() {
    return localStorage.getItem('client')
  },
  saveClient(client) {
    localStorage.setItem('client', client)
  },
  uid() {
    return localStorage.getItem('uid')
  },
  saveUid(uid) {
    localStorage.setItem('uid', uid)
  },

  getUserId() {
    if (location.search && 0 < location.search.length) {
      let search = location.search.substring(1);
      if (0 < search.length) {
        let params = search.split('&');
        if (0 < params.length) {
          let kvs = params.map(param => param.split('='));
          let kv = kvs.find(kv => kv[0] == 'user_id');
          if (kv) {
            return kv[1]
          }
        }
      }
    }
    return null
  },
  updateUrlWithUserId(url) {
    let userId = this.getUserId()
    if (userId) {
      if (url.includes('?')) {
        url = url + '&user_id=' + userId
      } else {
        url = url + '?user_id=' + userId
      }
    }
    return url
  },

  langs() {
    return ['ja', 'en', 'zh', 'ko', 'fr']
  },
  langText(lang) {
    return {
      'ja': '日本語',
      'en': 'English',
      'zh': '简体中文',
      'ko': '한국어',
      'fr': 'Français'
    }[lang]
  },
  langItems() {
    return [
      { value: 'ja', text: '日本語' },
      { value: 'en', text: 'English' },
      { value: 'zh', text: '简体中文' },
      { value: 'ko', text: '한국어' },
      { value: 'fr', text: 'Français' }
    ]
  },


  plans() {
    return [
      {
        stripe_lookup_key: 'product_guide_plan_free',
        code: 'free',
        name: 'フリー',
        max_quantity: 1,
        max_langs: 1,
        max_contents: 1,
        theme_editable: false,
        footer_editable: false
      },
      {
        stripe_lookup_key: 'product_guide_plan_light',
        code: 'light',
        name: 'ライト',
        max_quantity: 1,
        max_langs: 2,
        max_contents: 3,
        theme_editable: true,
        footer_editable: true
      },
      {
        stripe_lookup_key: 'product_guide_plan_standard',
        code: 'standard',
        name: 'スタンダード',
        max_quantity: 999,
        max_langs: 5,
        max_contents: 3,
        theme_editable: true,
        footer_editable: true
      },
      {
        stripe_lookup_key: 'product_guide_plan_enterprise',
        code: 'enterprise',
        name: 'エンタープライズ',
        max_quantity: 999,
        max_langs: 5,
        max_contents: 3,
        theme_editable: true,
        footer_editable: true
      },
      {
        stripe_lookup_key: 'product_guide_plan_special',
        code: 'special',
        name: 'スペシャル',
        max_quantity: 999,
        max_langs: 5,
        max_contents: 3,
        theme_editable: true,
        footer_editable: true
      }
    ]
  },


  colorGreen() {
    return '#00A663'
  },
  colorGreenDark() {
    return '#138B47'
  },
  colorGreenBackground() {
    return '#DCEDD0'
  },
  colorLightGreenBackground() {
    return '#F1F8E9'
  },
  colorRed() {
    return '#FF5252'
  },
  colorGrayBackground() {
    return '#F5F5F5'
  },

  colorPlanFree() {
    return 'rgba(255, 140, 86, 0.9)'
  },
  colorPlanLight() {
    return '#fecc5a'
  },
  colorPlanStandard() {
    return '#7fd5ab'
  },
  colorPlanEnterprise() {
    return 'rgba(107, 181, 187, 0.9)'
  }
}
