<template>
  <div></div>
</template>

<script>
import Common from './common.js'

export default {
  mounted() {
    if (Common.accessToken()) {
      location.href = '/admin'
    } else {
      location.href = '/login'
    }
  }
}
</script>
